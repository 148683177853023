<script setup>
import {formatRelative} from 'date-fns'
import {it} from 'date-fns/locale'
import {computed} from "vue";

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {}
    }
  }
})

const icons = {
  page: '/bundles/pimcoreadmin/img/flat-color-icons/template.svg',
  article: '/bundles/pimcoreadmin/img/flat-color-icons/text.svg',
  event: '/bundles/pimcoreadmin/img/flat-color-icons/calendar.svg',
  comunicato: '/bundles/pimcoreadmin/img/flat-color-icons/advertising.svg',
  bando: '/bundles/pimcoreadmin/img/flat-color-icons/view_details.svg',
  default: '/bundles/pimcoreadmin/img/flat-color-icons/document.svg'
}

const categoryIcon = computed(() => (props.item && icons.hasOwnProperty(props.item.type) ? icons[props.item.type] :  icons.default))

const round = (value) => value.toFixed(2)
const roundInt = (value) => Math.round(value)

const dateFormat = (date) => formatRelative(new Date(date), new Date(), {locale: it})

</script>
<template>
  <div
    v-if="item"
    :data-type="item.type"
    :data-score="item.score"
    class="px-8 py-4 grid grid-cols-5 justify-between space-x-2 odd:bg-white/70"
  >
    <div class="col-span-4 space-y-2">
      <h4 class="text-xl truncate w-full">{{ item.title }}</h4>
      <em class="text-sm text-neutral-400">{{ dateFormat(item.publicationDate) }}</em>
      <div v-html="item.description" class="text-sm"></div>
      <div class="flex justify-start items-center space-x-2">
        <img :src="categoryIcon" :alt="item.type" class="h-4 w-auto">
        <a :href="item.link" class="inline-block text-sm text-neutral-400 truncate w-full">{{ item.link }}</a>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center">
      <a :href="item.link" @click="keyword='';searchVisible=false;" class="btn btn--small">
        <span>Leggi</span>
        <div class="w-full bg-neutral-200 rounded-full">
          <div class="bg-green-700 text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
               :style="`width: ${round(item.score * 2)}%`"></div>
        </div>
      </a>
    </div>
  </div>
</template>
