<script setup>
import { onBeforeMount, reactive, computed } from "vue";

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel
} from "@headlessui/vue";

import {
  Bars3Icon,
  XMarkIcon
} from "@heroicons/vue/24/outline";

import HeaderLogo from "./header-logo.vue";
import HeaderMenuItem from "./header-menu-item.vue";
import HeaderMenuPopover from "./header-menu-popover.vue";
import HeaderMenuDiscosure from "./header-menu-disclosure.vue";
import HeaderSearchBtn from "./header-search-btn.vue";

const data = reactive({
  logo: null,
  menuItems: [],
  isHome: false,
  isFixed: false,
  isEditMode: false
});

const homeItem = computed(() => {
  return { icon: "home", label: "☗", href: "/", children: [] };
});

onBeforeMount(() => {
  if (typeof window !== "undefined") {
    if (typeof window.globalSiteConfig !== "undefined") {
      if (typeof window.globalSiteConfig.logo !== "undefined") {
        data.logo = window.globalSiteConfig.logo;
      }
      if (typeof window.globalSiteConfig.navigation.mainMenu !== "undefined") {
        data.menuItems = window.globalSiteConfig.navigation.mainMenu;
      }
      if (typeof window.globalSiteConfig.navigation.isHome !== "undefined") {
        data.isHome = !!window.globalSiteConfig.navigation.isHome;
      }
      if (typeof window.globalSiteConfig.navigation.headerFixed !== "undefined") {
        data.isFixed = !!window.globalSiteConfig.navigation.headerFixed;
      }
      if (typeof window.globalSiteConfig.editmode !== "undefined") {
        data.isEditMode = !!window.globalSiteConfig.editmode;
      }
      if (typeof window.globalSiteConfig.navigation.searchEnabled !== "undefined") {
        data.searchEnabled = !!window.globalSiteConfig.navigation.searchEnabled;
      }
    }
  }
});

</script>
<template>
  <Popover class="relative w-full max-w-full md:max-w-7xl md:mx-auto">
    <div
      class="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start space-x-4"
    >
      <!-- logo -->
      <div class="flex justify-start sm:flex-shrink-0">
        <HeaderLogo :logo-src="data.logo" logo-link="/" title="SogeMi Spa"></HeaderLogo>
      </div>
      <!-- mobile open menu button -->
      <div class="-mr-2 -my-2 md:hidden">
        <PopoverButton
          class="bg-white/60 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
        >
          <span class="sr-only">Open menu</span>
          <Bars3Icon aria-hidden="true" class="h-6 w-6" />
        </PopoverButton>
      </div>
      <PopoverGroup
        v-if="data.menuItems.length > 0"
        as="nav"
        class="hidden md:flex md:flex-1 items-center text-center flex-wrap justify-start space-x-2">
        <template
          v-for="item in [homeItem, ...data.menuItems]" :key="item.id"
        >
          <HeaderMenuItem
            v-if="item.children.length === 0"
            :fixed="data.isFixed"
            :item="item"
          />
          <HeaderMenuPopover
            v-else
            :fixed="data.isFixed"
            :item="item"
          />

        </template>
      </PopoverGroup>
      <div
        v-if="data.searchEnabled"
        class="hidden md:flex items-center justify-end"
      >
        <HeaderSearchBtn class="h-6" :fixed="data.isFixed" />
      </div>
    </div>
    <!-- mobile menu -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        class="absolute z-10 bg-white bg-opacity-90 h-screen overflow-y-auto top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        focus
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <HeaderLogo :logo-src="data.logo" logo-link="/" title="SogeMi Spa"></HeaderLogo>
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                >
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" class="h-6 w-6" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="py-6 px-5">
            <div class="grid grid-cols-1 gap-4">
              <!-- main menu items -->
              <template
                v-for="item in [homeItem, ...data.menuItems]" :key="item.id"
              >
                <HeaderMenuItem
                  v-if="item.children.length === 0"
                  :item="item"
                />
                <HeaderMenuDiscosure v-else :fixed="data.isFixed" :item="item" />

              </template>
              <!-- / main menu items -->
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
