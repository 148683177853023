<script setup>
import { onBeforeMount, reactive } from "vue";
import { useWindowScroll } from "@vueuse/core";

import HeaderMenu from "./header-menu.vue";

const { y } = useWindowScroll();

const data = reactive({
  isHome: false,
  isFixed: false,
  isEditMode: false
});

onBeforeMount(() => {
  if (typeof window !== "undefined") {
    if (typeof window.globalSiteConfig !== "undefined") {
      if (typeof window.globalSiteConfig.navigation.isHome !== "undefined") {
        data.isHome = window.globalSiteConfig.navigation.isHome;
      }
      if (typeof window.globalSiteConfig.navigation.headerFixed !== "undefined") {
        data.isFixed = window.globalSiteConfig.navigation.headerFixed;
      }
      if (typeof window.globalSiteConfig.editmode !== "undefined") {
        data.isEditMode = window.globalSiteConfig.editmode;
      }
    }
  }
});

</script>
<template>
  <header
    :class="{
    'position-fixed': !data.isEditMode,
    'bg-gray-gradient': y < 40 && data.isFixed,
    'bg-gray-700': y >= 40 && data.isFixed,
    'bg-white': y >= 40 && !data.isFixed
    }"
    class="transition-colors top-0 z-10 w-full px-0 md:px-4 lg:px-8 bg-opacity-95"
  >
    <HeaderMenu />
  </header>
</template>