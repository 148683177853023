<script setup>
import {computed, reactive, ref, watch} from 'vue';
import {debounce} from 'lodash';
import {
  MagnifyingGlassIcon,
  XMarkIcon
} from "@heroicons/vue/24/outline";

import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel
} from "@headlessui/vue";

import SearchItem from './header-search-item.vue';

defineProps({
  fixed: {
    type: Boolean,
    default: false
  }
});

const perPage = 10
const page = ref(1)
const keyword = ref('')
const searchVisible = ref(false)
const loading = ref(false)
const results = ref([])

const search = debounce(async function () {
  loading.value = true
  try {
    const elements = selectedTypes.value.map(e => e.value)
    const result = await fetch(`/api/public-content/search-by?q=${keyword.value}&types=${elements.join(',')}`)
    results.value = await result.json()
  } catch (e) {
    console.error(e)
  }
  loading.value = false
}, 500)

const maxSize = computed(() => {
  return results?.value?.length || 0
})

const pages = computed(() => {
  return maxSize > 0 ? maxSize / perPage : 0
})

const resultPage = computed(() => {
  const from = page.value > 0 && page.value <= pages ? (page.value - 1) * perPage : 0
  const to = from + perPage > maxSize ? maxSize : from + perPage
  console.log(from, to)
  return results.value.slice(from, to)
})

const allowedCategories = [
  {label: 'Pagine', value: 'page', icon: '/bundles/pimcoreadmin/img/flat-color-icons/template.svg'},
  {label: 'Notizie', value: 'article', icon: '/bundles/pimcoreadmin/img/flat-color-icons/text.svg'},
  {label: 'Eventi', value: 'event', icon: '/bundles/pimcoreadmin/img/flat-color-icons/calendar.svg'},
  {label: 'Comunicati', value: 'comunicato', icon: '/bundles/pimcoreadmin/img/flat-color-icons/advertising.svg'},
  {label: 'Bandi', value: 'bando', icon: '/bundles/pimcoreadmin/img/flat-color-icons/view_details.svg'}
]


const selectedTypes = ref([...allowedCategories])

watch(keyword, () => {
  search()
})

</script>
<template>
  <div>
    <MagnifyingGlassIcon
      v-if="!searchVisible"
      :class="{ 'menu-item--dark': fixed, 'menu-item--light': !fixed }"
      class="text-base font-medium h-full w-auto cursor-pointer"
      @click.stop.prevent="searchVisible = true"
    />
    <XMarkIcon
      v-else
      :class="{ 'menu-item--dark': fixed, 'menu-item--light': !fixed }"
      class="text-base font-medium h-full w-auto cursor-pointer"
      @click.stop.prevent="searchVisible = false"
    />
    <div v-if="searchVisible" class="fixed top-32 left-0 bg-neutral-50 w-full h-[calc(100vh-100px)] overflow-hidden"
         data-component="search-result">
      <div class="mx-auto max-w-4xl bg-neutral-100 p-8">
        <div class="flex flex-1 justify-center items-start px-2 lg:ml-6 space-x-2">
          <div class="w-full max-w-lg">
            <label class="sr-only" for="search">Cerca</label>
            <div class="relative mb-4">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
              </div>
              <input id="search" v-model="keyword"
                     class="block w-full rounded-md border border-transparent bg-gray-700 py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 focus:border-white focus:bg-white focus:text-gray-900 focus:outline-none focus:ring-white sm:text-sm"
                     name="search" placeholder="Cerca nel sito..." type="search"/>
            </div>
            <div class="w-full max-w-lg flex justify-start items-center space-x-2 hidden">
              <Listbox v-model="selectedTypes" as="div" multiple class="w-full flex justify-start space-x-4 items-center">
                <ListboxLabel class="inline-block text-sm font-medium text-gray-700">Ricerca in</ListboxLabel>
                <div class="relative flex-1">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedTypes && selectedTypes.length > 0 ? selectedTypes.map((cat) => cat.label).join(', ') : 'Seleziona le categorie...' }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
<ChevronUpDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
</span>
                  </ListboxButton>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                              leave-to-class="opacity-0">
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption v-for="cat in allowedCategories" :key="cat.value" v-slot="{ active, selected }"
                                     :value="cat" as="template">
                        <li
                          :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
    cat.label
  }}</span>

                          <span v-if="selected"
                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
<CheckIcon aria-hidden="true" class="h-5 w-5"/>
</span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
          <button
            :class="{ 'btn--disabled': keyword.length < 3 && !loading }"
            class="btn btn--default btn--small"
            @click.stop.prevent="keyword.length >= 3 && !loading && search()"
          >{{ loading ? 'Attendi...' : 'Cerca' }}
          </button>
        </div>
      </div>
      <div
        class="mx-auto max-w-4xl bg-neutral-100 h-[calc(100vh-200px)] lg:h-[calc(100vh-300px)] overflow-y-scroll space-y-4">
        <SearchItem
          v-for="item in resultPage"
          v-if="resultPage && resultPage.length > 0"
          :data-score="item.score"
          :data-type="item.type"
          :item="item"
          class="px-8 py-4 flex justify-between space-x-2 odd:bg-white/70 overflow-hidden"
        >
        </SearchItem>
        <div v-else-if="resultPage && resultPage.length === 0 && keyword.length >= 3" class="text-center">
          <strong>Nessun risultato trovato.</strong>
        </div>
      </div>
    </div>
  </div>
</template>
